import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="testimonial-wrap">
          <div className="container">
            <blockquote className="testimonial">
              <p><span>&ldquo;</span>Inspiration, works, resources and general interests of mine...<span>&rdquo;</span></p>
            </blockquote>
          </div>
        </section>
        <section className="section section--gradient content">
          <div className="container">
            <BlogRoll />
          </div>
        </section>
      </Layout>
    )
  }
}
